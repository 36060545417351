.container {
  padding: 0 5px;
}
.player-view .player {
  font-size: 12px !important;
}

.player-view .player .name {
  text-shadow: 1px 1px #000000 !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

